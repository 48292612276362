import { observer } from "@/helpers/UI";
import {
  ref,
  onMounted,
  onBeforeUnmount,
  onUpdated,
  watch,
  computed,
  type ComputedRef,
  isRef
} from "vue";
import type { MaybeRef, Ref } from "vue";
import type { Offset } from "@/models/common";
import type { MaybeElement } from "@vueuse/core";
import {
  invoke,
  until,
  useElementBounding,
  useElementSize,
  useParentElement,
  useResizeObserver
} from "@vueuse/core";
import { isTextOverflowing } from "@/helpers/UI";

/**
 * @deprecated Use "useTeleportV2" instead.
 */
export const useTeleport = (
  targetElementRef: Ref<HTMLElement | null>,
  scrollable: string,
  type: string | null = "menu",
  textRef?: Ref<HTMLElement | null> | null,
  horizontalScrollable?: string | null,
  scrollUp = true
) => {
  const top = ref<number>(0);
  const left = ref<number>(0);
  const right = ref<number>(0);

  const disconnect = ref<(() => void) | null>(null);
  const assignPositionValues = () => {
    if (!targetElementRef.value) {
      return;
    }
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = targetElementRef.value.getBoundingClientRect();
    if (type === "menu") {
      top.value = elemRect.top - bodyRect.top + 25;
      left.value = elemRect.left - bodyRect.left;
      right.value = bodyRect.right - elemRect.right;
    }
    if (type === "toolTip" && textRef?.value) {
      top.value = elemRect.top - textRef.value.offsetHeight - 10;
      left.value = elemRect.left - 6;
      right.value = bodyRect.right - elemRect.right - 6;
    }
  };

  const attachPositionListener = () => {
    if (scrollable) {
      document
        .getElementById(scrollable)
        ?.addEventListener("scroll", assignPositionValues);
    }
    if (horizontalScrollable) {
      document
        .getElementById(horizontalScrollable)
        ?.addEventListener("scroll", assignPositionValues);
    }
    if (window) {
      window.addEventListener("resize", assignPositionValues);
    }
  };

  const detachPositionListener = () => {
    if (scrollable) {
      document
        .getElementById(scrollable)
        ?.removeEventListener("scroll", assignPositionValues);
    }
    if (scrollUp) {
      document.getElementById(scrollable)?.scrollBy(0, -1);
    }
    if (horizontalScrollable) {
      document
        .getElementById(horizontalScrollable)
        ?.removeEventListener("scroll", assignPositionValues);
      document.getElementById(horizontalScrollable)?.scrollBy(0, -1);
    }
    if (window) {
      window.removeEventListener("resize", assignPositionValues);
    }
  };

  watch(targetElementRef, assignPositionValues);

  onMounted(() => {
    assignPositionValues();
    attachPositionListener();

    disconnect.value = observer({
      elem1: scrollable,
      elem2: horizontalScrollable,
      cb: assignPositionValues
    });
  });

  onUpdated(() => {
    assignPositionValues();
  });

  onBeforeUnmount(() => {
    detachPositionListener();
    if (disconnect.value) {
      disconnect.value();
    }
  });

  return {
    top,
    left,
    right,
    assignPositionValues,
    attachPositionListener,
    detachPositionListener
  };
};
export interface UseTeleportV2Options<T extends HTMLElement = HTMLElement> {
  target: Ref<T | null>;
  to?: string;
  maintainSizeInOriginalPlace?: boolean;
  offset?: MaybeRef<Offset>;
  checkForOverflow?: boolean;
}

/**
 * Utility to perform a teleport of an element which can't overflow elements up in the hierarchy
 *
 * @experimental This hook is not fully tested yet.
 * @param options
 * @returns void
 */
export const useTeleportV2 = <T extends HTMLElement>({
  target,
  to = "#modals",
  maintainSizeInOriginalPlace,
  offset,
  checkForOverflow
}: UseTeleportV2Options<T>) => {
  const initialCssPosition = ref<CSSStyleDeclaration["position"]>("");
  const reactiveParentElement = useParentElement(target);
  const originalParent = ref<typeof reactiveParentElement.value>(null);
  const { width: targetWidth, height: targetHeight } = useElementSize(target);
  const { top: parentTop, left: parentLeft } =
    useElementBounding(originalParent);

  const teleportParent = document.querySelector(to);
  const teleported = ref(false);
  const parsedOffset = isRef(offset) ? offset : ref(offset);

  const updatePosition = () => {
    if (!target.value || !originalParent.value) {
      return;
    }
    let newTop =
      parentTop.value +
      (parsedOffset?.value?.top ? parsedOffset?.value.top : 0);

    const newLeft =
      parentLeft.value +
      (parsedOffset?.value?.left ? parsedOffset?.value.left : 0);

    /** Check if element is overflowing the window bottom */
    if (
      checkForOverflow &&
      newTop + target.value.offsetHeight > window.innerHeight
    ) {
      newTop = window.innerHeight - target.value.offsetHeight;
    }

    target.value.style.top = newTop + "px";
    target.value.style.left = newLeft + "px";
  };

  if (maintainSizeInOriginalPlace) {
    watch(
      [targetWidth, targetHeight],
      ([newWidth, newHeight]) => {
        if (!originalParent.value) {
          return;
        }
        originalParent.value.style.width = newWidth + "px";
        originalParent.value.style.height = newHeight + "px";
      },
      { immediate: true }
    );
  }

  invoke(async () => {
    await until(reactiveParentElement).toBeTruthy();
    originalParent.value = reactiveParentElement.value;
  });

  watch([parentTop, parentLeft], updatePosition, { immediate: true });

  watch(
    target,
    () => {
      if (!target.value || target.value.style.position === "absolute") {
        return;
      }
      if (!initialCssPosition.value) {
        initialCssPosition.value = target.value.style.position;
      }
      target.value.style.position = "absolute";
      if (!teleported.value) {
        if (teleportParent?.contains(target.value)) {
          teleportParent.removeChild(target.value);
        }
        teleportParent?.appendChild(target.value);
        teleported.value = true;
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    if (!target.value) {
      return;
    }
    target.value.style.position = initialCssPosition.value;
    if (teleportParent?.contains(target.value)) {
      teleportParent.removeChild(target.value);
    }
    teleported.value = false;
  });

  onUpdated(updatePosition);
};

export const useElementBoundingLimited = (
  source: Ref<MaybeElement>,
  options?: { offsetLeft?: number; offsetTop?: number }
) => {
  const { top, left } = useElementBounding(source);
  const localTop = ref(top.value);
  const localLeft = ref(left.value);

  if (options) {
    watch([top, left], ([newTop, newLeft]) => {
      if (
        options.offsetLeft &&
        newLeft + options.offsetLeft > window.innerWidth
      ) {
        newLeft = window.innerWidth - options.offsetLeft;
      }
      if (
        options.offsetTop &&
        newTop + options.offsetTop > window.innerHeight
      ) {
        newTop = window.innerHeight - options.offsetTop;
      }
      localTop.value = newTop;
      localLeft.value = newLeft;
    });
  }

  return { top: localTop, left: localLeft };
};

type ElementStatusReturnType<T> = T extends (HTMLElement | null)[]
  ? ComputedRef<boolean[]>
  : ComputedRef<boolean>;

export const useElementStatus = () => {
  const isTextTruncated = <
    T extends (HTMLElement | null) | (HTMLElement | null)[]
  >(
    element: Ref<T>
  ) =>
    computed(() => {
      const targetElement = element.value;
      if (Array.isArray(targetElement)) {
        return targetElement.map((el) => isTextOverflowing(el));
      }
      return isTextOverflowing(targetElement);
    }) as ElementStatusReturnType<T>;

  const isTextTruncatedOnResize = <T extends HTMLElement | null>(
    element: Ref<T>
  ) => {
    const elementClientWidth = ref(0);
    const elementScrollWidth = ref(0);

    useResizeObserver(document.body, () => {
      elementClientWidth.value = element.value?.clientWidth || 0;
    });

    onMounted(() => {
      elementClientWidth.value = element.value?.clientWidth || 0;
      elementScrollWidth.value = element.value?.scrollWidth || 0;
    });

    return computed(() => elementScrollWidth.value > elementClientWidth.value);
  };

  return { isTextTruncated, isTextTruncatedOnResize };
};

export const waitForElement = (
  selector: string,
  observable?: HTMLElement | null
): Promise<Element | null> => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    setTimeout(() => {
      if (!document.querySelector(selector)) {
        observer.disconnect();
        reject("Element not found");
      }
    }, 5000);

    observer.observe(observable ?? document.body, {
      childList: true,
      subtree: true
    });
  });
};
